import React from 'react';
import * as styles from './chat-box.module.css';


const ChatBox = (props) => {
    const { header, body } = props;
    const time = new Date().toTimeString().split(`:`).slice(0, 2).join(`:`);

    return (
        <div className={styles.root}>
            <div className={styles.message}>
                <span className={styles.triangle_top_right} />
                <span className={styles.message_title}>{header.brandName}</span>
                <p className={styles.message_body}>{body.greetingText}</p>
                <span className={styles.message_time}>{time}</span>
                <div>
                    {body.quickLinks.map((link, index) => <div key={index} className={styles.message_quicklink}>
                        <a target="_blank" href={link?.link} className={styles.message_body}>{link?.title}</a>
                    </div>)}
                </div>
            </div>
        </div>
    );
};

export default ChatBox;
