import React, { Fragment, useContext, useEffect } from 'react';
import { ChatContext } from '../../contexts/chat.context';
import ChatBox from '../chat-box/chat-box.component';
import Header from '../header/header.component';
import WaButton from '../wa-button/wa-button.component';
import * as styles from './app.module.css';


const App = (props) => {
    const { isChatOpen, setIsChatOpen } = useContext(ChatContext);

    useEffect(() => {
        if (props.open) {
            setIsChatOpen(true);
        }
    }, []);

    return (
        <Fragment>
            <div
                className={`${styles.root} ${isChatOpen ? styles.open : styles.close
                    }`}
            >
                <Header {...props.header} />
                <ChatBox {...props} />
            </div>
                
                <WaButton {...props.waButton} />
        </Fragment>
    );
};

export default App;
