import { AiOutlineClose } from 'react-icons/ai';
import React, { useContext } from 'react';
import { ChatContext } from '../../contexts/chat.context';

import * as styles from './header.module.css';

const Header = ({
    logo,
    brandName,
    subTitle,
    headerColor,
    headerTextColor,
}) => {
    const { setIsChatOpen } = useContext(ChatContext);

    const handleOpen = () => {
        setIsChatOpen(false);
    };

    return (
        <div className={styles.root} style={{ backgroundColor: headerColor, color: headerTextColor }}>
            <span className={styles.close_btn} onClick={handleOpen}>
                <AiOutlineClose />
            </span>
            <div>
                <img alt="logo" src={logo}  className={styles.logo}/>
            </div>
            <div className={styles.texts} style={{ color: headerTextColor }}>
                <span className={styles.texts_h1}>{brandName}</span>
                <span className={styles.texts_span}>{subTitle}</span>
            </div>
        </div>
    );
};

export default Header;
