import React from 'react';
import { ReactComponent as CompanyIcon } from './assets/crown.svg';
import { ChatProvider } from './widget/contexts/chat.context';
import WidgetApp from './widget/components/app/app.component';

const App = (props) => {
    
    return (
        <ChatProvider>
            <WidgetApp {...props}/> 
        </ChatProvider>
    );
};

export default App;
