import React, { useContext } from 'react';
// import { FaWhatsapp } from 'react-icons/fa';
import { ChatContext } from '../../contexts/chat.context';
import * as styles from './wa-button.module.css';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'


const WaButton = (props) => {
    const { isChatOpen, setIsChatOpen } = useContext(ChatContext);

    const handleOpen = () => {
        setIsChatOpen(!isChatOpen);
    };

    return (

        <div className={styles.root} onClick={handleOpen}>
            {/* <span id="my-element" data-tooltip-content={props?.waButtontext} className={styles.buttonText} style={{ background: props?.waButtonBgColor, color: props?.waButtonTextColor}}> {props?.waButtontext} </span> */}
            <div className={styles.imageContainer} >
                <img src={props?.waButtonicon} alt={"Icon"} id="my-element" data-tooltip-content={props?.waButtontext} />
            </div>
            <Tooltip anchorId="my-element" place="left" style={{color:props?.waButtonTextColor , backgroundColor:props?.waButtonBgColor, fontSize:16}} />
        </div>
    );
};

export default WaButton;
