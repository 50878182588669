import './index.css'

import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App'

window.initApp = (props) => {
    // Find all widget divs
    const WidgetDivs = document.querySelectorAll('#whatsapp-widget');
    // Inject React App into each
    
    WidgetDivs.forEach((Div, index) => {
        const root  = createRoot(Div);
        root.render(
            <React.StrictMode key={index}>
                <App {...props}/>
            </React.StrictMode>,
            
        );
    })
}
